exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-branding-tsx": () => import("./../../../src/pages/branding.tsx" /* webpackChunkName: "component---src-pages-branding-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-seo-tsx": () => import("./../../../src/pages/seo.tsx" /* webpackChunkName: "component---src-pages-seo-tsx" */),
  "component---src-pages-social-media-tsx": () => import("./../../../src/pages/social-media.tsx" /* webpackChunkName: "component---src-pages-social-media-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-ui-ux-design-tsx": () => import("./../../../src/pages/ui-ux-design.tsx" /* webpackChunkName: "component---src-pages-ui-ux-design-tsx" */),
  "component---src-pages-web-development-tsx": () => import("./../../../src/pages/web-development.tsx" /* webpackChunkName: "component---src-pages-web-development-tsx" */),
  "component---src-pages-web-optimization-tsx": () => import("./../../../src/pages/web-optimization.tsx" /* webpackChunkName: "component---src-pages-web-optimization-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

